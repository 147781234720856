.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 10px;
  padding: 20px;
  width: 100%;
  max-height: cover;
  min-width: 100px;
  background-color: #EFF9FC;
  z-index: 1;
  cursor: pointer;
  border-radius: 12px;
}
.product:hover {
  box-shadow: 4px 8px 8px -8px #D6CDEA;
  transform: scale(1.02);
  transition: all 0.01s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.product__rating {
  display: flex;
}

.product > img {
  max-height: 200px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 15px;
}

.product > button {
  background: #ccc6d8;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  color: #111;
  width: 170px;
  height: 35px;
  border-radius: 14px;
}
.product > button:hover {
  transform: scale(1.23);
  color: rgb(18, 18, 19);
  border-radius: 12px;
  transition: all 0.2s  cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.product__price {
  margin-top: 5px;
}

.product__info {
  height: 100px;
  margin: 15px 0px;
}
