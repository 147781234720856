.header {
  height: 60px;
  display: flex;
  align-items: center;
  background: #eaf4f7;
  position: sticky;
  top: 0;
  z-index: 100;
}

.header__logo {
  width: 100px;
  object-fit: contain;
  margin: 0 20px;
  margin-top: 7px;
}
input.header__searchInput {
  border: none;
}
.header__searchInput {
  height: 17px;
  padding: 10px;
  border: none;
  width: 100%;
}
.header__search {
  display: flex;
  flex: 1 1;
  align-items: center;
  border-radius: 24px;
}
.header__searchIcon {
  padding: 5px;
  width: 2em;
  height: 22px !important;
  background-color: #f79438;
}
.header__optionLineOne {
  font-size: 10px;
}
.header__optionLineTwo {
  font-size: 13px;
  font-weight: 800;
}
.header__nav {
  display: flex;
  justify-content: space-evenly;
}
.header__nav a {
    text-decoration: none;
    color: rgb(255, 121, 69);
}

.header__option {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}

.header__optionBasket {
  display: flex;
  align-items: center;
}

.header__basketCount {
  margin: 0px 10px;
}

@media only screen and (max-width: 600px) {
  .header {
    height: 120px;
    flex-direction: column;
    
  }
  .header__nav {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}
}
@media only screen and (min-width: 600px) {
}
@media only screen and (min-width: 768px) {
}
