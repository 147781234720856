.dialog__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 90%;
  max-width: 400px;
  animation: fadeIn 0.3s ease-in;
  text-align: center;
}

.dialog__logo {
  width: 100px;
  margin-bottom: 20px;
}

.dialog p {
  color: #333;
  margin-bottom: 20px;
  line-height: 1.4;
}

.dialog button {
  background: #f0c14b;
  border: 1px solid;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
  padding: 8px 30px;
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  margin-top: 10px;
}

.dialog button:hover {
  background: #f4d078;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -48%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}