@import url('https://fonts.googleapis.com/css2?family=Grandstander:wght@300&family=PT+Sans&display=swap');

.checkout {
  display: flex;
  padding: 70px;
  background-color: #EFF9DA;
  height: max-content;
  justify-content: space-around;
}

.checkout__ad {
  width: 100%;
  margin-bottom: 10px;
}
.checkout__title {
  margin-right: 10px;
  padding: 10px;
  border-bottom: 1px solid lightgray;
}
.user__info {
   font-family: 'Grandstander', cursive; 
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 2px;
  color: #747377;
}
.checkoutProduct:nth-child(even) {
  background: #CDF5A5;
}

@media only screen and (max-width: 600px) {
  .checkout {
    flex-direction: column;
  }
}
@media only screen and (min-width: 600px) {
  
}
@media only screen and (min-width: 768px) {}

