.payment {
    background: white;

}
.payment__container > h1 {
 text-align: center;
 padding: 10px;
 font-weight: 400;
 background-color: rgb(234,237,237);
 border-bottom: 1px solid lightgrey;
}
.payment__container > h1 a {
    text-decoration: none;
}

.payment__section {
    display: flex;
    padding: 20px;
    margin: 0px 20px;
    border-bottom: 1px solid lightgray;
}

.payment__title {
    flex: 0.2;
}
.payment__address, .payment__items,.payment__details {
    flex: 0.8;
}

.payment__details > form {
    max-width: 400px;
}

.payment__details > h3 {
    padding-bottom: 20px;
}

.payment__details > form > div > button {
    background-color: rgb(137, 248, 137);
    width: 100%;
    height: 38px;
    border-radius: 2px;
    margin-top: 10px;
    font-weight: 3000;

}