.subtotal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 200px;
    padding: 20px;
    background-color: #f3f3f3;
    border: 1px solid #dddddd;
    border-radius: 3px;
    border-radius:17px ;
}
.subtotal__gift {
    display: flex;
    align-items: center;
}
.subtotal__gift > input {
    margin-right: 5px;
}
.subtotal > button {
    background-color: #f0e14b;
    border-radius: 2px;
    width: 100%;
    height: 30px;
    margin-top: 10px;
    color: #111;
    border-radius: 12px;
}
.transval {
    animation: rot .422s cubic-bezier(0.23, 1, 0.320, 1);
}

@keyframes rot {
    from {
        transform: rotateX(0deg);
    
    }
    to {
        transform: rotateX(360deg);
        color: rgb(250, 81, 29);
    }
}