@import url(https://fonts.googleapis.com/css2?family=Grandstander:wght@300&family=PT+Sans&display=swap);
* {
   margin: 0;
   box-sizing: border-box;
}
body {
  background-color: #CDF5F6;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}



.header {
  height: 60px;
  display: flex;
  align-items: center;
  background: #eaf4f7;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 100;
}

.header__logo {
  width: 100px;
  object-fit: contain;
  margin: 0 20px;
  margin-top: 7px;
}
input.header__searchInput {
  border: none;
}
.header__searchInput {
  height: 17px;
  padding: 10px;
  border: none;
  width: 100%;
}
.header__search {
  display: flex;
  flex: 1 1;
  align-items: center;
  border-radius: 24px;
}
.header__searchIcon {
  padding: 5px;
  width: 2em;
  height: 22px !important;
  background-color: #f79438;
}
.header__optionLineOne {
  font-size: 10px;
}
.header__optionLineTwo {
  font-size: 13px;
  font-weight: 800;
}
.header__nav {
  display: flex;
  justify-content: space-evenly;
}
.header__nav a {
    text-decoration: none;
    color: rgb(255, 121, 69);
}

.header__option {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
}

.header__optionBasket {
  display: flex;
  align-items: center;
}

.header__basketCount {
  margin: 0px 10px;
}

@media only screen and (max-width: 600px) {
  .header {
    height: 120px;
    flex-direction: column;
    
  }
  .header__nav {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}
}
@media only screen and (min-width: 600px) {
}
@media only screen and (min-width: 768px) {
}

.home {
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
}

.home__row {
  display: flex;
  z-index: 1;
  margin:0px auto;
  width: 92%;
}

.home__image {
  width: 100%;
  z-index: -1;
  margin-bottom: -150px;
  -webkit-mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
          mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}
.product {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  margin: 10px;
  padding: 20px;
  width: 100%;
  max-height: cover;
  min-width: 100px;
  background-color: #EFF9FC;
  z-index: 1;
  cursor: pointer;
  border-radius: 12px;
}
.product:hover {
  box-shadow: 4px 8px 8px -8px #D6CDEA;
  transform: scale(1.02);
  transition: all 0.01s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.product__rating {
  display: flex;
}

.product > img {
  max-height: 200px;
  width: 100%;
  object-fit: contain;
  margin-bottom: 15px;
}

.product > button {
  background: #ccc6d8;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  color: #111;
  width: 170px;
  height: 35px;
  border-radius: 14px;
}
.product > button:hover {
  transform: scale(1.23);
  color: rgb(18, 18, 19);
  border-radius: 12px;
  transition: all 0.2s  cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.product__price {
  margin-top: 5px;
}

.product__info {
  height: 100px;
  margin: 15px 0px;
}

.dialog__backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
}

.dialog {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 90%;
  max-width: 400px;
  -webkit-animation: fadeIn 0.3s ease-in;
          animation: fadeIn 0.3s ease-in;
  text-align: center;
}

.dialog__logo {
  width: 100px;
  margin-bottom: 20px;
}

.dialog p {
  color: #333;
  margin-bottom: 20px;
  line-height: 1.4;
}

.dialog button {
  background: #f0c14b;
  border: 1px solid;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
  padding: 8px 30px;
  border-radius: 3px;
  cursor: pointer;
  width: 100%;
  font-size: 14px;
  margin-top: 10px;
}

.dialog button:hover {
  background: #f4d078;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -48%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(-50%, -48%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}
.login {
    background-color: white;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login__logo {
    margin-top: 20px;
    margin-bottom: 20px;
    object-fit: contain;
    width: 100px;
    margin-right: auto;
    margin-left: auto;
  }

.login__container {
    width: 300px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 20px;
}

.login__container > h1 {
    font-weight: 500;
    margin-bottom: 20px;
  }

.login__container > form > h5 {
    margin-bottom: 5px;
}

.login__container > form > input {
    height: 30px;
    margin-bottom: 10px;
    background-color: white;
    width: 98%;
  }

.login__container > p {
    margin-top: 15px;
    font-size: 12px;
}

.login__signInButton {
    background: #f0c14b;
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    border-color: #a88734 #9c7e31 #846a29;
  }

  .login__registerButton {
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    border-color: darkgray;
  }
  
  
.checkout {
  display: flex;
  padding: 70px;
  background-color: #EFF9DA;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
  justify-content: space-around;
}

.checkout__ad {
  width: 100%;
  margin-bottom: 10px;
}
.checkout__title {
  margin-right: 10px;
  padding: 10px;
  border-bottom: 1px solid lightgray;
}
.user__info {
   font-family: 'Grandstander', cursive; 
  font-weight: 400;
  font-size: 17px;
  letter-spacing: 2px;
  color: #747377;
}
.checkoutProduct:nth-child(even) {
  background: #CDF5A5;
}

@media only screen and (max-width: 600px) {
  .checkout {
    flex-direction: column;
  }
}
@media only screen and (min-width: 600px) {
  
}
@media only screen and (min-width: 768px) {}


.subtotal {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 300px;
    height: 200px;
    padding: 20px;
    background-color: #f3f3f3;
    border: 1px solid #dddddd;
    border-radius: 3px;
    border-radius:17px ;
}
.subtotal__gift {
    display: flex;
    align-items: center;
}
.subtotal__gift > input {
    margin-right: 5px;
}
.subtotal > button {
    background-color: #f0e14b;
    border-radius: 2px;
    width: 100%;
    height: 30px;
    margin-top: 10px;
    color: #111;
    border-radius: 12px;
}
.transval {
    -webkit-animation: rot .422s cubic-bezier(0.23, 1, 0.320, 1);
            animation: rot .422s cubic-bezier(0.23, 1, 0.320, 1);
}

@-webkit-keyframes rot {
    from {
        transform: rotateX(0deg);
    
    }
    to {
        transform: rotateX(360deg);
        color: rgb(250, 81, 29);
    }
}

@keyframes rot {
    from {
        transform: rotateX(0deg);
    
    }
    to {
        transform: rotateX(360deg);
        color: rgb(250, 81, 29);
    }
}
.checkoutProduct {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  align-items: center;
}

.checkoutProduct__info {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.checkout__cancel > button {
  background: #fd9e8d;
  margin-top: 10px;
  color: #111;
  border-radius: 10px;
}
.checkout__cancel > button:hover {
 transform: scale(1.1);
 transition: all .2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.checkoutProduct__image {
  object-fit: contain;
  width: 180px;
  height: 180px;  
}

.checkoutProduct__rating {
  display: flex;
}

.checkoutProduct__title {
  font-size: 17px;
  font-weight: 800;
}

.payment {
    background: white;

}
.payment__container > h1 {
 text-align: center;
 padding: 10px;
 font-weight: 400;
 background-color: rgb(234,237,237);
 border-bottom: 1px solid lightgrey;
}
.payment__container > h1 a {
    text-decoration: none;
}

.payment__section {
    display: flex;
    padding: 20px;
    margin: 0px 20px;
    border-bottom: 1px solid lightgray;
}

.payment__title {
    flex: 0.2 1;
}
.payment__address, .payment__items,.payment__details {
    flex: 0.8 1;
}

.payment__details > form {
    max-width: 400px;
}

.payment__details > h3 {
    padding-bottom: 20px;
}

.payment__details > form > div > button {
    background-color: rgb(137, 248, 137);
    width: 100%;
    height: 38px;
    border-radius: 2px;
    margin-top: 10px;
    font-weight: 3000;

}
.orders {
    padding: 20px 80px;
}

.orders > h1 {
    margin: 30px 0;
}
