.checkoutProduct {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  cursor: pointer;
  align-items: center;
}

.checkoutProduct__info {
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.checkout__cancel > button {
  background: #fd9e8d;
  margin-top: 10px;
  color: #111;
  border-radius: 10px;
}
.checkout__cancel > button:hover {
 transform: scale(1.1);
 transition: all .2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.checkoutProduct__image {
  object-fit: contain;
  width: 180px;
  height: 180px;  
}

.checkoutProduct__rating {
  display: flex;
}

.checkoutProduct__title {
  font-size: 17px;
  font-weight: 800;
}
